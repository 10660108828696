html {
  margin: 0;
  padding: 0;
  min-width: 90vw;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  padding: 0;
  min-width: 100vw;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  color: black;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  text-align: left;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.subtitle {
  padding: 20px;
  font-size: large;
  font-weight: lighter;
  color: white;
}

.fillParent {
  display:block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#statusBar {
  background-color: black;
  color: white;
}

#background-video{
  height: 100%;
  width: 100%;
  float: left;
  top: 0;
  padding: 0;
  position: fixed; /* optional depending on what you want to do in your app */
  object-fit: fill;
}


#link-code {
  text-decoration: none;
  font-size: xx-large;
  font-weight: bolder;
  color: white;
  letter-spacing: 5px;
}

#pixon-heading-url {
  text-decoration: none;
  font-size: large;
  font-weight: lighter;
  color: white;
}

#pixon-heading-url:visited {
  color: white;
  font-weight: lighter;
}

.horizontalCENTER {
  text-align: center;
}

.verticalCENTER {
  vertical-align: middle;
}

.horizontalLEFT {
  text-align: left;
}

.verticalTOP {
  vertical-align: top;
}

.horizontalRIGHT {
  text-align: right;
}

.verticalBOTTOM {
  vertical-align: bottom;
}

.app-qr {
  position:absolute;
  bottom:5vh;
  right: 5vw;
}

.button {
  position: relative;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.button:after {
  content: "";
  background: #0097f6;
  display: block;
  position: absolute;

  opacity: 0;
  transition: all 0.8s
}

.button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}
